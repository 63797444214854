.sceneTwo_guide {
    position: fixed;
    top: 15%;
    left: 1rem;
    padding: 5rem 2rem 2.5rem;
    padding-left: 2rem;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #00000030;
    border-radius: 20px;
    z-index: 1000;
    backdrop-filter: blur(10px);
    transition: 1s;

    .infinity_1 {
        position: absolute;
        top: 2.5rem;
        left: 1.8rem;
        width: 40px;

        path {
            stroke-width: 2px;
            fill: none;
            stroke: #0000002c;
        }
    }

    .infinity_2 {
        position: absolute;
        top: 2.5rem;
        left: 1.8rem;
        width: 40px;

        path {
            stroke-width: 2px;
            fill: none;
            transition: 0.4s ease-out;
            stroke-dashoffset: 188;
            stroke: white;
        }
    }
}

.guide_main {
    font-family: MyriadVariableConcept, 'Microsoft_JhengHei', sans-serif;
}

.guide_ball {
    margin-right: 1rem;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    transition: 0.5s;
}

.guide_title {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    transition: 0.5s;
    cursor: pointer;
}

.guide_item_group {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.guide_item {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    font-size: 12px;
    font-family: MyriadVariableConcept, sans-serif;
    transition: 0.5s;
    cursor: pointer;
}

.guide_line {
    margin-left: 4px;
    width: 1px;
    height: 100%;
    background-color: white;
}
