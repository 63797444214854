.detailDialog {
    position: fixed;
    top: calc(15% - 4rem);
    left: calc(27.5% - 2rem);
    overflow: hidden;
    padding: 4rem 2rem;
    width: 45%;
    height: 70%;
    font-family: Din, sans-serif;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    z-index: 990;
    backdrop-filter: blur(10px);
    pointer-events: visible;

    .cross-btn {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        background-color: #000;
        border-radius: 50%;

        &:hover {
            opacity: 0.8;
        }

        svg {
            width: 16px;

            .cls-1 {
                fill: #fff;

                // stroke: #000;
            }
        }
    }

    &-scrollbar {
        overflow-y: scroll;
        width: 100%;
        height: 100%;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #a5a5a5;
            border-radius: 35px;
        }
    }
}

@keyframes showDialog {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes closeDialog {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0);
    }
}

.showDialog {
    opacity: 1;
    transition: 1s;
    transform: scale(1);
    transition-timing-function: cubic-bezier(0.84, 0, 0.02, 1);

    // animation-name: showDialog;
    // animation-duration: 1s;
    // animation-timing-function: cubic-bezier(0.84, 0, 0.02, 1);
}

.closeDialog {
    opacity: 0;
    transition: 1s;
    transform: scale(0);
    transition-timing-function: cubic-bezier(0.84, 0, 0.02, 1);

    // animation-name: closeDialog;
    // animation-duration: 1s;
    // animation-timing-function: cubic-bezier(0.84, 0, 0.02, 1);
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 800;
}
