.loadingPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
    z-index: 16512964;
    transition: 1s;
}

.loadingPage-logo {
    // border: 1px solid #000;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -205px; //-145px;
    margin-left: -205px; //-140px;
    width: 410px; //280
    height: 228px; //210
    // transform: scale(0.95);

    img {
        // width: 280px;
        // height: 160px;
        // transform: scale(0.95);
        width: 410px;
        height: 228px;
        // transform: scale(0.95);
    }
}

// .loadingPage-loadingCircle {
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     margin-top: -41px;
//     margin-left: -200px;
//     transform: scale(0.3);
// }

.loadingPage-progress {
    // border: 1px solid #000;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -150px;
    width: 300px;
    height: 100px;
    font-size: 12px;
    font-family: Din, sans-serif;
    font-weight: bold;
    text-align: center;
    line-height: 100px;
    color: #76181d;

    .logo {
        width: 43%;
    }

    &-first {
        font-size: 12px;
        letter-spacing: 7px;
        font-family: MyriadVariableConcept, sans-serif;
    }

    &-percentage {
        // border: 1px solid #000;
        // transform: translateY(-20px);
        // transform: translateY(96px);
        transform: translateY(14px);
    }
    &-loadingCircle {
        // border: 1px solid #000;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -21px; //-41px
        margin-left: -200px;
        transform: scale(0.3);
    }
}

@media screen and (max-width: 768px) {
    .loadingPage-logo {
        transform: scale(0.85);

        img {
            transform: scale(0.85);
        }
    }

    .loadingPage-progress-loadingCircle {
        margin-top: -28px; //-46
    }

    .loadingPage-progress {
        margin-top: -45px;

        &-first {
            font-size: 12px;
            letter-spacing: 4.5px;
            font-family: MyriadVariableConcept, sans-serif;
        }
    }
}

@keyframes enterBtnOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.loadingPage-enter-btn {
    margin: 0 auto;
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    font-family: Din, sans-serif;
    font-weight: bold;
    text-align: center;
    color: #76181d;
    background-color: #fff;
    border: 1px solid #76181d;
    border-radius: 25px;
    transform: translate(0, -1rem);
    animation-name: enterBtnOpacity;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    letter-spacing: 5px;

    &:hover {
        opacity: 0.8;
    }
}

.loadingPage-visit-bbin {
    position: fixed;
    bottom: 5rem;
    left: 50%;
    display: flex;
    overflow: hidden;
    margin-left: -80px;
    width: 160px;
    font-size: 14px;
    font-family: Din, sans-serif;
    text-align: center;
    white-space: nowrap;
    color: #76181d;

    // border-bottom: 1px solid #76181d;
    transform: translate(3px, 0);
    cursor: pointer;
    flex-direction: column;
    letter-spacing: 2px;

    .span_one {
        width: 50%;
        height: 1px;
        background-color: #76181d;
        transition: 0.2s;
    }

    .span_two {
        width: 50%;
        height: 1px;
        background-color: #76181d;
        transition: 0.2s;
    }

    &:hover {
        .span_one {
            transform: translate(-100%, 0);
        }

        .span_two {
            transform: translate(100%, 0);
        }
    }
}

.hint {
    position: absolute;
    bottom: 5.8%;
    left: 50%;
    font-size: 1rem;
    white-space: nowrap;
    color: #888;
    transform: translateX(-50%);
    letter-spacing: 0.1rem;
}
