@keyframes sceneOneBtnsLoop {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    15% {
        opacity: 0;
        transform: scale(2);
    }

    20% {
        opacity: 0;
        transform: scale(1);
    }

    30% {
        opacity: 1;
        transform: scale(1);
    }

    45% {
        opacity: 0;
        transform: scale(2);
    }

    50% {
        opacity: 0;
        transform: scale(1);
    }

    60% {
        opacity: 0;
        transform: scale(1);
    }

    75% {
        opacity: 0;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.sceneOneBtns {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;

    &::before {
        pointer-events: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-image: linear-gradient(
            rgba(30, 30, 30, 0.2) 0%,
            rgba(30, 30, 30, 0) 30%,
            rgba(30, 30, 30, 0) 75%,
            rgb(30, 30, 30, 0.5) 100%
        );
        z-index: 1000;
        content: '';
    }

    .video-js {
        video {
            object-fit: cover;
            object-position: center top;
            width: 100vw;
            height: 100vh;
        }
    }

    .bg-back {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center top;
        background-size: 1920px auto;
        z-index: 1;

        @media (min-width: 1920px) {
            background-size: 100% auto;
        }

        &.mobile {
            background-size: 100% auto;
        }
    }

    .bg {
        position: relative;
        width: 100%;
        height: 100%;
        background-position: center top;
        background-size: 1920px auto;
        z-index: 2;

        @media (min-width: 1920px) {
            background-size: 100% auto;
        }

        &.mobile {
            background-size: 100% auto;
        }
    }

    .sceneOne-Btn-divider {
        width: 50px;
        height: 1px;
        background-color: #fff;
        transform: translate(0, 0.5rem);
    }

    .btn-circle {
        position: relative;
        margin-bottom: 1rem;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        z-index: 1111;

        &::before {
            position: absolute;
            left: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #fff;
            border-radius: 50%;
            content: '';
            animation-name: sceneOneBtnsLoop;
            animation-duration: 3s;
            animation-iteration-count: infinite;
        }
    }

    .sceneOneBtns-group {
        // 定位用
        width: 520px; //400px
        position: fixed;
        // top: calc(50% + 240px);
        bottom: 22vh; //15vh
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center; //space-evenly
        z-index: 1005;
        z-index: 1003;
        transform: translate(-50%);
        flex-direction: row; //左到右
        gap: 10px;
        &:hover .sceneOneBtns-group-btn:not(:hover) {
            filter: blur(2px);
            opacity: 0.5;
            transform: scale(0.95);
        }
        &-btn {
            position: relative;
            white-space: nowrap;
            color: #fff;
            flex: 1;
            flex-direction: column;
            transition: all 0.3s ease;
            // 語系寬度設定
            &.en:nth-child(1) {
                flex: 0 0 26%;
            }
            &.en:nth-child(2) {
                flex: 0 0 37%;
            }
            &.en:nth-child(3) {
                flex: 0 0 37%;
            }
            &:hover > :not(:hover) {
                border: 1px solid #000;
            }
            .content-container {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                width: inherit; //100%, inherit 繼承父元素, 但是flex1不能被繼承
                pointer-events: visible;
                background: rgba(#000, 0.4); //#000
                backdrop-filter: blur(20px); //20px
                line-height: 3rem; //3rem
                border-radius: calc(3rem / 2);
                transition: 0.7s;
                box-shadow: 0 10px 20px 0 rgba(#000, 0.1);
                &:hover {
                    color: #000;
                    background: rgba(#fff, 0.85); //#fff, daa156
                }
            }
        }
    }

    // clareAdd
    .sceneOneBtns-wrap {
        width: 100vw; //800px
        height: 100vh; //220px, 100vh
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1002; //1003
        transition: all 0.3s ease-in-out; // 過渡效果

        &.hovered {
            backdrop-filter: blur(15px);
            background: rgba(#000, 0.5);
        }
    }
}

.sceneOneBtns-text {
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 720px;
    font-size: 4rem;
    font-family: Coco;
    text-align: center;
    white-space: pre;
    color: #daa156;
    opacity: 0;
    z-index: 111111111;
    transform: translateX(-50%) translateY(-50%);
    letter-spacing: -3px;
    user-select: none;
    pointer-events: none;

    @media (min-width: 576px) {
        font-size: 5rem;
    }

    @media (min-width: 992px) {
        font-size: 7rem;
    }
    span {
        position: relative;
        opacity: 0;
    }
}

.fade-btns-enter {
    opacity: 0;
}

.fade-btns-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.fade-btns-enter-done {
    opacity: 1;
}

.fade-btns-exit {
    opacity: 1;
}

.fade-btns-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.fade-btns-exit-done {
    opacity: 0;
}
