@keyframes contactDialog_open {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes contactDialog_close {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

.contactDialog_open {
    animation-name: contactDialog_open;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.84, 0, 0.02, 1);
}

.contactDialog_close {
    animation-name: contactDialog_close;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.84, 0, 0.02, 1);
}

.contactFormDailog {
    position: fixed;
    top: 50%;
    right: 1rem;
    overflow-y: auto;
    margin-top: calc(-50vh + 1rem);
    padding: 4rem 2rem;
    width: 25rem;
    height: calc(100vh - 10rem);
    max-height: calc(100vh - 10rem);
    font-family: MyriadVariableConcept, 'Microsoft_JhengHei', sans-serif;
    color: #494949;
    background-color: #fff;
    border-radius: 18px;
    z-index: 16352620;
    transition: all 1s;
    pointer-events: visible;

    &::-webkit-scrollbar {
        width: 0;
    }

    .cross_btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        background-color: #000;
        border-radius: 50%;

        &:hover {
            opacity: 0.8;
        }

        svg {
            width: 16px;

            .cls-1 {
                fill: #fff;
            }
        }
    }

    .title_group {
        padding-bottom: 2rem;

        p {
            font-size: 0.9rem;
            font-weight: bold;
            line-height: 1.3rem;
        }

        h3 {
            padding-bottom: 1rem;
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        gap: 0.5rem;

        &:last-child {
            padding-bottom: 0;
        }
    }

    .sub_btn {
        padding: 0 1rem;
        height: 2.5rem;
        font-size: 0.9rem;
        line-height: 2.5rem;
        color: #fff;
        letter-spacing: 2px;
        background-color: #231815;
        border: 1px solid #231815;
        border-radius: 8px;
        flex: 1;

        &:nth-child(2) {
            background-color: #b92a24;
            border: 1px solid #b92a24;
        }

        &:disabled {
            background-color: gray;
            border: 1px solid gray;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    .inputTextArea {
        display: flex;
        align-items: center;
        padding: 1rem;
        border: 1px solid #d6d6d6;
        border-radius: 8px;
        flex: 1;

        .error {
            &::placeholder {
                color: #f00;
            }
        }

        textarea {
            width: 100%;
            height: 8rem;
            font-size: 0.8rem;
            background-color: #fff;
            border: none;
            border-radius: 10px;
            resize: none;

            &::placeholder {
                font-size: 0.8rem;
                font-family: MyriadVariableConcept;
                font-weight: 400;
                color: #494949;
            }

            &:focus {
                outline: none;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .contactFormDailog {
        left: 50%;
        margin-top: calc(-32.5vh - 4rem);
        margin-left: calc(-50vw + 1rem);
        width: calc(100vw - 6rem);
        height: calc(65vh);
        max-height: calc(65vh);
        z-index: 16352620;

        .cross_btn {
            width: 36px;
            height: 36px;
        }
    }
}
