.inputText {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 44px;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    flex: 1;

    .error {
        &::placeholder {
            color: #f00 !important;
        }
    }

    input {
        width: 100%;
        max-height: 2.5rem;
        font-size: 0.8rem;
        background-color: #fff;
        border: none;
        border-radius: 10px;

        &::placeholder {
            font-size: 0.8rem;
            font-family: MyriadVariableConcept;
            font-weight: 400;
            color: #494949;
        }

        &:focus {
            outline: none;
        }
    }
}
