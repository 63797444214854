.btn-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    pointer-events: none;
    // background: #000;

    &.bottom {
        @keyframes fade-in {
            5% {
                opacity: 0;
            }

            80% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        animation: fade-in 5s linear;
    }
}

.fixedButtonGroup-logo {
    position: fixed;
    top: 1.95rem; //top: 1.95rem; logoR: 1.2rem
    left: 2.45rem; //left: 2.45rem; logoR: 3rem
    width: 5.9375rem; //width: 5.9375rem; logoR: 12rem
    font-size: 20px;
    font-weight: bold;
    color: white;
    z-index: 1000;
    pointer-events: visible;
    cursor: pointer;

    &.mobile {
        top: 2.1rem;
        left: 2rem;
        width: 4.5rem;
    }
}

.fixedButtonGroup-rightTop {
    position: fixed;
    top: 2rem;
    right: 1.5rem;
    display: flex;
    z-index: 1000;
    flex-direction: row;

    .fixedButtonGroup-rightTop {
        &-language {
            color: #fff;

            svg {
                .cls-1 {
                    stroke: #fff;
                }
            }

            &-en,
            &-cn {
                color: #fff;

                &:hover,
                &.selected {
                    color: #000;
                }
            }
        }

        &-bbin {
            color: #fff;

            svg {
                .cls-1 {
                    stroke: #fff;
                }

                .cls-2 {
                    fill: #fff;
                }
            }

            &:hover {
                color: #000;

                svg {
                    .cls-1 {
                        stroke: #000;
                    }

                    .cls-2 {
                        fill: #000;
                    }
                }
            }
        }

        &-contact {
            color: #fff;

            svg {
                fill: #fff;
            }

            &:hover {
                color: #000;

                svg {
                    fill: #000;
                }
            }
        }
    }

    &-language {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        font-family: Din, sans-serif;
        font-weight: bold;
        pointer-events: visible;

        svg {
            margin: 0 0.5rem;
            width: 30px;
            height: 30px;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-en,
        &-cn {
            font-weight: bold;
            transition: 0.4s;
            letter-spacing: 2px;
        }
    }

    &-bbin {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        font-size: 13.3333px; //13.3333px
        font-family: Din, sans-serif;
        font-weight: bold;
        text-decoration: none;
        transition: 0.4s;
        pointer-events: visible;

        p {
            letter-spacing: 2px;
        }

        svg {
            margin: 0 0.5rem;
            width: 32px;
            height: 32px;
            transition: 0.4s;

            .cls-1 {
                transition: 0.4s;
            }

            .cls-2 {
                transition: 0.4s;
            }
        }
    }

    &-contact {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        font-size: 13.3333px;
        font-family: Din, sans-serif;
        font-weight: bold;
        text-decoration: none;
        transition: 0.4s;
        pointer-events: visible;

        p {
            letter-spacing: 2px;
        }

        svg {
            margin: 0 0.5rem;
            width: 32px;
            height: 32px;
            transition: 0.4s;
        }
    }
}

$animation-duration: 0.3s;

@keyframes scale-animation {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.fixedButtonGroup-rightBottom {
    position: fixed;
    right: 1.5rem;
    bottom: 3rem;
    display: flex;
    z-index: 1000;
    flex-direction: row;

    &:hover {
        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: 20px;
            margin-left: -50px;
            width: 100px;
            font-size: 14px;
            font-family: Din, sans-serif;
            color: #fff;
            content: 'CONTACT';
            letter-spacing: 3px;
            animation-name: scale-animation;
            animation-duration: $animation-duration;
        }

        .fixedButtonGroup-rightBottom-chat {
            transform: scale(5);
        }

        .fixedButtonGroup-rightBottom-circle-bk {
            transform: scale(5);
        }
    }

    &-circle-bk {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1rem;
        width: 48px;
        height: 48px;
        font-weight: bold;
        border-radius: 50%;
        transition: $animation-duration;
        transform: scale(0);
        flex-direction: column;
    }

    &-chat {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1rem;
        width: 48px;
        height: 48px;
        font-weight: bold;
        background-color: #fff;
        border-radius: 50%;
        transition: $animation-duration;
        flex-direction: column;
    }

    img {
        position: absolute;
        top: 6px;
        left: calc(1rem + 6px);
        width: 36px;
    }
}

.fixedButtonGroup-leftBottom {
    position: fixed;
    right: 2.5rem;
    bottom: 3rem;
    display: flex;
    z-index: 1000;
    flex-direction: row;
    pointer-events: visible;

    svg {
        position: absolute;
        top: 50%;
        left: calc(1rem + 6px);
        margin-top: -12px;
        width: 36px;
    }
}

.fixedButtonGroup-rightTop-mobile {
    display: none;
}

.volume-icon {
    width: 48px;
    fill: #fff;
}

@media screen and (max-width: 992px) {
    .fixedButtonGroup-rightTop,
    .fixedButtonGroup-rightBottom {
        display: none;
    }

    .fixedButtonGroup-rightTop-mobile {
        position: fixed;
        top: 2rem;
        right: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: #fff;
        border-radius: 50%;
        z-index: 1000;
        flex-direction: row;
        pointer-events: visible;

        svg {
            width: 18px;
        }

        &-dialog {
            position: fixed;
            top: 1rem;
            right: 1rem;
            padding: 4rem 2rem 1.5rem;
            background-color: #fff;
            border-radius: 18px;
            z-index: 9999;

            .dialog-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 1rem 0;
                border-bottom: 1px solid #d6d6d6;

                &:first-child {
                    padding: 0 0 1rem;
                }

                &:last-child {
                    border-bottom: none;
                }

                .dialog-svg {
                    padding-right: 0.7rem;

                    svg {
                        width: 36px;
                    }
                }

                .dialog-content {
                    font-family: MyriadVariableConcept, 'Microsoft_JhengHei',
                        sans-serif;
                    letter-spacing: 1px;
                }
            }
        }
    }

    .fixedButtonGroup-logo {
        left: 1rem;
    }

    .fixedButtonGroup-leftBottom {
        right: 2.5rem;
        bottom: 3rem;
    }

    .volume-icon {
        img {
            width: 36px;
        }
    }
}
