.screenOrientation {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    font-weight: bold;
    text-align: center;
    background-color: #fff;
    z-index: 1000000000;

    img{
        padding-top: 2rem;
        width: 25vh;
    }
}

@media screen and (orientation: portrait) {
    .screenOrientation {
        display: none;
    }
}

@media screen and (orientation: landscape) {
    .screenOrientation {
        display: flex;
    }
}
