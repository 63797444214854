/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    font-size: 100%;
    border: 0;
    /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

@font-face {
    font-family: Din;
    src: url('./assets/fonts/DIN Alternate Bold OK.ttf') format('opentype');
}

@font-face {
    font-family: 'Din2014';
    src: url('./assets/fonts/DIN 2014 Bold.ttf') format('opentype');
}

@font-face {
    font-family: MyriadVariableConcept;
    src: url('./assets/fonts/MyriadVariableConcept-Roman.otf')
        format('opentype');
}

@font-face {
    font-family: msheij;
    src: url('./assets/fonts/msheij.ttf') format('opentype');
}

@font-face {
    font-family: 'Microsoft_JhengHei';
    src: url('./assets/fonts/Microsoft_JhengHei_Bold.ttf') format('opentype');
}

@font-face {
    font-family: Coco;
    src: url('./assets/fonts/BoonTook-Regular.ttf')
        format('opentype');
}
