.fixed-button-group-scroll {
    width: 100vw;
    height: 100vh;
    transition: all 0.3s;
    transform: none !important;
    pointer-events: none;

    .scroll-container {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8rem;
        height: 8rem;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        backdrop-filter: blur(5px);
        flex-direction: column;

        .icon {
            padding-bottom: 0.5rem;

            svg {
                width: 36px;
                height: 36px;
            }
        }

        .text {
            display: block;
            font-size: 14px;
            font-family: Din, sans-serif;
            letter-spacing: 3px;
        }
    }
}