.mobileCarousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    pointer-events: none;
    gap: 2rem;
}

.text-container {
    overflow: hidden;
}

.mobileCarousel-text {
    font-size: 1rem;
    text-align: center;

    .title {
        font-size: 2.8rem;
        font-family: Coco, sans-serif;
        font-weight: 400;
        font-weight: bold;

        @media (min-width: 576px) {
            font-size: 3rem;
        }
    }

    .years {
        margin-top: 1rem;
        letter-spacing: 3px;
        font-family: Din, sans-serif;

        @media (min-width: 576px) {

        }
    }
}

.mobileCarousel {
    pointer-events: visible;
    position: relative;
    display: flex;
    align-items: center;

    // margin-bottom: 2rem;
    width: 100vw;
    z-index: 2;
    transition: 0.6s;

    &-left,
    &-right {
        position: absolute;
        top: calc(50% - 18px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        color: #000;
        background-color: #fff;
        border-radius: 50%;
        z-index: 3;
    }

    &-left {
        left: 5vw;
    }

    &-right {
        right: 5vw;
    }

    &-item {
        // transition: 0.5s;

        img,
        video {
            width: 80vw;
            height: 50vw;
            background-color: black;
        }

        &-cover {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            width: 80vw;
            height: 50vw;
            font-size: 2rem;
            text-align: center;
            color: #fff;
            background-color: #00000054;
            flex-direction: column;

            p {
                font-size: 1rem;
                font-family: Din, sans-serif;

                &.width-smaller {
                    margin: 0 auto;
                    width: 90%;

                    &:nth-child(1) {
                        font-size: 3rem;
                    }
                }

                &:nth-child(1) {
                    font-size: 2.5rem;

                    @media (min-width: 576px) {
                        font-size: 3.3rem;
                    }
                }

                &:nth-child(2) {
                    margin-top: 0.5rem;
                }
            }
        }
    }
}

.titleCarousel {
    pointer-events: none;

    // position: fixed;
    // bottom: calc(15vh);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;

    // height: 100vh;
    z-index: 2;
    flex-direction: column;

    &-title {
        padding: 0.5rem 1.5rem;
        font-size: 1rem;
        color: #000;
        border-radius: 30px;
        pointer-events: visible;
    }

    &-divider {
        margin: 0.5rem 0;
        width: 1px;
        height: 1.5vh;
        background-color: #000;
    }
}

@media screen and (max-height: 768px) {
    .mobileCarousel-container {
        padding-top: 0.5rem;
        gap: 1rem;
    }
}

.mobile-detailDialog-btnGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;

    @media (min-width: 576px) {
        width: 80%;
    }

    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        line-height: 24px;

        .btn-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.5rem;
            padding: 2px;
            border: 1px solid #000;
            border-radius: 50%;

            svg {
                width: 18px;
                height: 18px;
            }
        }

        &:nth-child(2) {
            .btn-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                margin-left: 0.5rem;
                border: 1px solid #000;
                border-radius: 50%;
            }
        }
    }
}
