.sceneOneBtns-group-mobile-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    font-family: Din;
    color: #fff;
    background-color: #000000b6;
    border-radius: 50%;
    letter-spacing: 3px;
    transform: translateY(2rem);
    pointer-events: visible;

    p {
        text-align: center;
        transform: translateX(2px);
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 66px;
        height: 66px;
        border: 2px solid #fff;
        border-radius: 50%;
        z-index: -1;
        content: '';
        animation-name: sceneOneBtnsLoop;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }
}
