.inputText-list {
    position: absolute;
    top: 44px;

    // bottom: -100%;
    left: -1px;
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    z-index: 1005;
    transition: 0.3s;
    flex-direction: column;
    flex: 1;
}

.inputText-list-item {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    z-index: 2;
    cursor: pointer;

    &:hover {
        background-color: #e0e0e0;
    }
}

.select-input {
    input {
        &:disabled {
            opacity: 1;

            &::placeholder {
                opacity: 1;
            }
        }
    }
}
