body {
    // cursor: url('path/to/cursor.png') 10 10, auto;
    // cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><circle cx="8" cy="8" r="4" fill="white"/></svg>')
    //         8 8,
    //     auto;

    &::-webkit-scrollbar {
        width: 0;
    }
}

.experience {
    position: relative;
    z-index: 1;
    pointer-events: visible;

    canvas {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}

.cover-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    object-fit: cover;
    object-position: center top;
}

.loading-wrap {
    // border: 10px solid #000;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    opacity: 0; //0, 1
    z-index: 4;
    pointer-events: none;

    .loading-effect {
        position: absolute;
        top: 10%;
        left: 10.5%;
        width: 80%;
        @media (min-width: 992px) {
            top: 0;
            left: 35%;
            width: 29.6875%;
        }
    }

    .loading-hint {
        position: absolute;
        top: calc(50% + 130px);
        width: 6rem;
        @media (min-width: 992px) {
            width: 8.4rem;
        }

        .spin-circle {
            display: block;
            width: 100%;
            height: auto;
        }

        .text {
            // 只有文字而已
            position: absolute;
            top: 45px;
            left: 50%;
            font-size: 0.8rem;
            color: #fff;
            transform: translate(-50%, -50%);
            @media (min-width: 992px) {
                font-size: 1rem;
            }
        }
    }
}

.fade-video-enter {
    opacity: 0;
}

.fade-video-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.fade-video-enter-done {
    opacity: 1;
}

.fade-video-exit {
    opacity: 1;
}

.fade-video-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.fade-video-exit-done {
    opacity: 0;
}

.fade-loading-wrap-enter {
    opacity: 0;
}

.fade-loading-wrap-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.fade-loading-wrap-enter-done {
    opacity: 1;
}

.fade-loading-wrap-exit {
    opacity: 1;
}

.fade-loading-wrap-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.fade-loading-wrap-exit-done {
    opacity: 0;
}

.loading-tip {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
    height: 20px;
    line-height: 20px;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    border-radius: 3px;
    text-align: center;
    font-size: 12px;
    .dots {
        padding-left: 5px;
    }
    .dots span {
        display: inline-block;
        animation: dotAnimation 1.5s infinite;
        animation-fill-mode: both;
        &:nth-child(2) {
            animation-delay: 0.2s;
        }
        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }
}

@keyframes dotAnimation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-canvas-enter {
    opacity: 0;
}

.fade-canvas-enter-active {
    opacity: 1;
    // transition: opacity 300ms;
    // transition: opacity 10000ms ease-in-out;
    animation: fadeIn 10000ms linear forwards;
}

.fade-canvas-enter-done {
    opacity: 1;
}

.fade-canvas-exit {
    opacity: 1;
}

.fade-canvas-exit-active {
    opacity: 0;
    // transition: opacity 300ms;
    // transition: opacity 10000ms ease-in-out;
    animation: fadeOut 10000ms linear forwards;
}

.fade-canvas-exit-done {
    opacity: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.video-wrap {
    // background: url('/leadingPageAssets/indexPage/video_pc_bg.jpg') no-repeat center center;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
}
