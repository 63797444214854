@keyframes imgAnimation {
    from {
        height: 0;
    }

    to {
        height: 500px;
    }
}

.mobile-detailDialog {
    position: fixed;
    top: calc(15% - 4rem);
    left: calc(12.5% - 2rem);
    overflow: hidden;
    overflow-y: auto;
    padding: 4rem 2rem;
    width: 75vw;
    height: 70%;
    font-family: Din, sans-serif;
    background-color: #fff;
    border-radius: 20px;
    z-index: 999999999;
    transition: 0.5s;
    transform: scale(0);

    .cross-btn {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        background-color: #000;
        border-radius: 50%;

        svg {
            width: 16px;

            .cls-1 {
                fill: #fff;
            }
        }
    }

    &-main {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-title {
            height: 40px;
            font-size: 40px;
            font-family: 'Din2014', sans-serif;
            font-weight: bold;
            text-align: center;
            line-height: 40px;
            color: #b92a24;
            transition: 0.7s;
        }

        &-title-h3 {
            overflow: hidden;
            width: 100%;
            font-size: 24px;
            font-family: 'Microsoft_JhengHei', Din, sans-serif;
            font-weight: bold;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 24px;
            color: #494949;
            transition: 0.7s;
            letter-spacing: -1.3px;
        }

        .main-img {
            padding-bottom: 1rem;
            width: 100%;
            height: 210px;

            &.youtube {
                width: 300px;

                @media (min-width: 576px) {
                    width: 400px;
                }

                @media (min-width: 768px) {
                    width: 500px;
                }
            }

            @media (min-width: 576px) {
                height: 300px;
            }

            video{
                width: 100%;
                height: 210px;
                object-fit: cover;

                @media (min-width: 576px) {
                    height: 300px;
                }
            }
        }

        .main-p {
            padding: 2rem 1rem;
            font-size: 14.5px;
            font-family: MyriadVariableConcept, msheij, sans-serif;
            text-align: start;
            line-height: 28px;
            color: #000;
            transition: 0.7s;

            @media (min-width: 576px) {
                padding: 2rem 10%;
            }
        }
    }
}

.mobile-detailDialog-img {
    overflow: hidden;
    animation-name: imgAnimation;
    animation-duration: 0.7s;
    animation-timing-function: ease;
}

.backdrop {
    z-index: 3;
}