@keyframes imgAnimation {
    from {
        height: 0;
    }

    to {
        height: 500px;
    }
}

$content-open-duration: 0.7s;

.aboutBbin {
    display: flex;
    align-items: centers;
    justify-content: center;
    margin: 0 auto;
    width: 600px;
    font-family: MyriadVariableConcept, sans-serif;
    text-align: center;
    flex-direction: column;

    &-title {
        height: 40px;
        font-size: 40px;
        font-family: 'Din2014', sans-serif;
        font-weight: bold;
        line-height: 40px;
        color: #b92a24;
        transition: $content-open-duration;
    }

    &-title-h3 {
        font-size: 20px;
        font-family: 'Microsoft_JhengHei', Din, sans-serif;
        font-weight: bold;
        color: #494949;
        transition: $content-open-duration;
    }

    &-p {
        padding: 0 1rem;
        padding-bottom: 2rem;
        font-size: 14.5px;
        font-family: MyriadVariableConcept, msheij, sans-serif;
        text-align: start;
        line-height: 24px;

        // color: #494949;
        color:#000;
        transition: $content-open-duration;
    }

    .dialog-animation-contaner-img {
        overflow: hidden;
        animation-name: imgAnimation;
        animation-duration: $content-open-duration;
        animation-timing-function: ease;

        img {
            height: 400px;
        }
    }

    .aboutBbin-img {
        width: 100%;
    }
}
